@font-face {
  font-family: "Fakt";
  src: url("./font-fakt/Fakt-Thin.woff2") format("woff2"),
    url("./font-fakt/Fakt-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Fakt";
  src: url("./font-fakt/Fakt-ThinItalic.woff2") format("woff2"),
    url("./font-fakt/Fakt-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Fakt Thin";
  src: url("./font-fakt/Fakt-Thin.woff2") format("woff2"),
    url("./font-fakt/Fakt-Thin.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Fakt Thin";
  src: url("./font-fakt/Fakt-ThinItalic.woff2") format("woff2"),
    url("./font-fakt/Fakt-ThinItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Fakt";
  src: url("./font-fakt/Fakt-Light.woff2") format("woff2"),
    url("./font-fakt/Fakt-Light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Fakt";
  src: url("./font-fakt/Fakt-LightItalic.woff2") format("woff2"),
    url("./font-fakt/Fakt-LightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Fakt Light";
  src: url("./font-fakt/Fakt-Light.woff2") format("woff2"),
    url("./font-fakt/Fakt-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Fakt Light";
  src: url("./font-fakt/Fakt-LightItalic.woff2") format("woff2"),
    url("./font-fakt/Fakt-LightItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Fakt";
  src: url("./font-fakt/Fakt-Blond.woff2") format("woff2"),
    url("./font-fakt/Fakt-Blond.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Fakt";
  src: url("./font-fakt/Fakt-BlondItalic.woff2") format("woff2"),
    url("./font-fakt/Fakt-BlondItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Fakt Blond";
  src: url("./font-fakt/Fakt-Blond.woff2") format("woff2"),
    url("./font-fakt/Fakt-Blond.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Fakt Blond";
  src: url("./font-fakt/Fakt-BlondItalic.woff2") format("woff2"),
    url("./font-fakt/Fakt-BlondItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Fakt";
  src: url("./font-fakt/Fakt-Normal.woff2") format("woff2"),
    url("./font-fakt/Fakt-Normal.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Fakt";
  src: url("./font-fakt/Fakt-NormalItalic.woff2") format("woff2"),
    url("./font-fakt/Fakt-NormalItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Fakt";
  src: url("./font-fakt/Fakt-Medium.woff2") format("woff2"),
    url("./font-fakt/Fakt-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Fakt";
  src: url("./font-fakt/Fakt-MediumItalic.woff2") format("woff2"),
    url("./font-fakt/Fakt-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Fakt Medium";
  src: url("./font-fakt/Fakt-Medium.woff2") format("woff2"),
    url("./font-fakt/Fakt-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Fakt Medium";
  src: url("./font-fakt/Fakt-MediumItalic.woff2") format("woff2"),
    url("./font-fakt/Fakt-MediumItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Fakt";
  src: url("./font-fakt/Fakt-SemiBold.woff2") format("woff2"),
    url("./font-fakt/Fakt-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Fakt";
  src: url("./font-fakt/Fakt-SemiBoldItalic.woff2") format("woff2"),
    url("./font-fakt/Fakt-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Fakt SemiBold";
  src: url("./font-fakt/Fakt-SemiBold.woff2") format("woff2"),
    url("./font-fakt/Fakt-SemiBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Fakt SemiBold";
  src: url("./font-fakt/Fakt-SemiBoldItalic.woff2") format("woff2"),
    url("./font-fakt/Fakt-SemiBoldItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Fakt";
  src: url("./font-fakt/Fakt-Bold.woff2") format("woff2"),
    url("./font-fakt/Fakt-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Fakt";
  src: url("./font-fakt/Fakt-BoldItalic.woff2") format("woff2"),
    url("./font-fakt/Fakt-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Fakt SemiBold";
  src: url("./font-fakt/Fakt-Bold.woff2") format("woff2"),
    url("./font-fakt/Fakt-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Fakt SemiBold";
  src: url("./font-fakt/Fakt-BoldItalic.woff2") format("woff2"),
    url("./font-fakt/Fakt-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Fakt";
  src: url("./font-fakt/Fakt-Black.woff2") format("woff2"),
    url("./font-fakt/Fakt-Black.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Fakt";
  src: url("./font-fakt/Fakt-BlackItalic.woff2") format("woff2"),
    url("./font-fakt/Fakt-BlackItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Fakt Black";
  src: url("./font-fakt/Fakt-Black.woff2") format("woff2"),
    url("./font-fakt/Fakt-Black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Fakt Black";
  src: url("./font-fakt/Fakt-BlackItalic.woff2") format("woff2"),
    url("./font-fakt/Fakt-BlackItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-container-child {
  max-width: 600px;
  background-color: #f7edeb;
  font-family: "Fakt";
  font-size: 22px;
  letter-spacing: -0.85px;
  padding: 12px 16px 12px 17px;
  font-weight: 300;
  margin-top: 38px !important;
}

.checkClass {
  width: 28px;
  height: 28px;
}

.checkClass:checked::before {
  background: url("./image/check.png");
  content: "";
  background-color: white;
  background-size: 100%;
  border-radius: 2px;
}

.redirection-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  font-size: 1.5em;
}

.hidden {
  display: none;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
  margin: 73px 0 120px;
}

.addressInput {
  font-size: 20px;
  padding: 19px;
  border: 1px solid #dfdfdf;
  width: -webkit-fill-available;
  margin: 6px 0px 0px 0px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {
  width: 18px;
  height: 18px;
  border: 5px dotted #fff;
  border-radius: 50%;
  display: inline-block;
  -webkit-animation: rotation 3s linear infinite;
  animation: rotation 3s linear infinite;
}

.loader.location-loader {
  position: absolute;
  top: 17px;
  right: 18px;
  border: 5px dotted #dfdfdf;
}

html {
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
}

body {
  touch-action: pan-x pan-y;
}

.calanderWrapper {
  position: relative;
  width: 320px;
}

.calanderWrapper .react-datepicker-wrapper .react-datepicker__input-container input[type="text"] {
  width: 320px;
  padding: 20px 12px 19px 19px;
  box-sizing: border-box;
  font-size: 18px;
  border: 1px solid #dfdfdf;
}

.calanderWrapper .calenderIcon {
  width: 26px;
  position: absolute;
  right: 14px;
  top: 17px;
  pointer-events: none;
}

.calenderIcon img {
  max-width: 100%;
  pointer-events: none;
}

.calanderWrapper .react-datepicker-wrapper .react-datepicker__input-container input[type="text"].error {
  border-color: #ff0000;
}

.birthCalender {
  width: 247px;
}

.birthCalender .react-datepicker-wrapper .react-datepicker__input-container input[type="text"],
.birthCalender .react-datepicker-wrapper .react-datepicker__input-container input[type="text"] {
  width: 100%;
}

.birthCalender .react-datepicker-wrapper {
  display: block;
}

@media screen and (max-width: 768px) {
  .calanderWrapper .react-datepicker-wrapper .react-datepicker__input-container input[type="text"] {
    width: 100%;
  }

  .calanderWrapper .react-datepicker-wrapper {
    display: block;
  }
}